@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;400&display=swap');

.back-gradient{
  background-image: linear-gradient(65deg , #000000 , #1C3575);
  /* overflow-y: auto; */
}
.App {
  background-image: linear-gradient(65deg , #000000 , #1C3575);
  
  overflow-y: auto;
}
.App2 {
  background-image: linear-gradient(90deg , #ffffff , #ffffff);

}

.back-map{
  background-image: url("../src/assets/worldMap.png");
  overflow: hidden;
}

.filter-drop{
  background-color: #0e0e0e96;
}

.brand-section{
  background-image: linear-gradient(90deg , #F7F7F7 , #F7F7F7);
}
.yeseva-font{
  font-family: 'Yeseva One', serif;
};

.bg-wrapper{
  background-image: url("../src/assets/aboutus-bgwrapper.jpeg");
  overflow: hidden;
}

.mulish-font{
  font-family: 'Mulish', sans-serif;
};

.perfume{
  cursor: pointer;
  transform: scale(1.1);
  transition: all 1s linear;
}

.fade-in-text{
  animation: fade-in 1.5s linear;
}

.side-nav{
  position: fixed;
  width: 500px;
  height: 100vh;
  right: -500px;
  transition: all 0.8s ease-in-out;
}

.side-active-nav{
  position: fixed;
  width: 500px;
  height: 100vh;
  right: 0px;
  transition: all 0.8s ease-in-out;
}

.button-fade-out{
  animation: fade-out 0.8s linear;
}

@keyframes fade-out{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
};

@keyframes fade-in{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
};

.nav-animation{
  animation: slide 4s linear;
};

@keyframes slide{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@media only screen and (min-width:768px) {
  .hover-underline-animation > .child:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 80px;
    top: 0px;
    z-index: -10;
    left: -10px;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    margin: 10px;
    border-radius: 5px;
  }
  
  .hover-underline-animation:hover > .child:after{
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@media only screen and (max-width:768px) {
  .side-nav{
    position: fixed;
    width: 350px;
    height: 100vh;
    right: -350px;
    transition: all 0.8s ease-in-out;
  }
  
  .side-active-nav{
    position: fixed;
    width: 350px;
    height: 100vh;
    right: 0px;
    transition: all 0.8s ease-in-out;
  }
}